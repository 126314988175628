<template>
  <v-container fill-height>
    <v-layout justify-center>
      <v-flex xs12 sm12 md11 lg10 xl8>
        <v-form class="elevation-5">
          <template>
            <v-toolbar color="primary" dark dense>
              <v-icon class="mr-2">mdi-clipboard-account-outline</v-icon>
              <v-toolbar-title>{{ tituloForm }}</v-toolbar-title>
              <v-spacer></v-spacer>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metClear"
                  >
                    mdi-refresh
                  </v-icon>
                </template>
                <span>Refresh</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metVoltar"
                  >
                    mdi-arrow-left-circle-outline
                  </v-icon>
                </template>
                <span>Voltar</span>
              </v-tooltip>
            </v-toolbar>
          </template>

          <v-tabs>
            <v-tab> Principais </v-tab>
            <v-tab> Adicionais </v-tab>
            <v-tab-item>
              <template>
                <v-container fluid>
                  <v-row dense>
                    <v-col md="2" align-self="center">
                      <v-text-field
                        dense
                        hide-details="auto"
                        v-model="varTransportadorLocal.id"
                        label="ID"
                        readonly
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col md="10" align-self="center">
                      <v-text-field
                        dense
                        hide-details="auto"
                        v-model="varTransportadorLocal.nomeFantasia"
                        label="Nome Fantasia"
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col md="9" align-self="center">
                      <v-text-field
                        dense
                        hide-details="auto"
                        v-model="varTransportadorLocal.nomeRazao"
                        label="Nome/Razão Social"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col md="3" align-self="center">
                      <v-text-field
                        dense
                        hide-details="auto"
                        v-model="varTransportadorLocal.cpfCnpj"
                        label="CPF/CNPJ"
                        v-mask="metMaskCpfCnpj(varTransportadorLocal.cpfCnpj)"
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="auto" md="3" align-self="center">
                      <v-text-field
                        dense
                        hide-details="auto"
                        v-model="varTransportadorLocal.inscricaoEstadual"
                        label="Inscrição Estadual"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="auto" md="3" align-self="center">
                      <v-text-field
                        dense
                        hide-details="auto"
                        v-model="
                          varTransportadorLocal.inscricaoEstadualSubstitutoTributario
                        "
                        label="Insc. Est. Substituto Tributário"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="auto" md="3" align-self="center">
                      <v-text-field
                        dense
                        hide-details="auto"
                        v-model="varTransportadorLocal.cuit"
                        label="CUIT"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="auto" md="3" align-self="center">
                      <v-text-field
                        dense
                        hide-details="auto"
                        v-model="varTransportadorLocal.rut"
                        label="RUT"
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="auto" md="3" align-self="center">
                      <v-text-field
                        dense
                        hide-details="auto"
                        v-model="varTransportadorLocal.apolice"
                        label="Apólice"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="auto" md="3" align-self="center">
                      <v-text-field
                        dense
                        type="date"
                        hide-details="auto"
                        v-model="varTransportadorLocal.vencimentoApolice"
                        label="Vencimento Apólice"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="auto" md="3" align-self="center">
                      <v-text-field
                        dense
                        hide-details="auto"
                        v-model="varTransportadorLocal.paut"
                        label="Paut"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="auto" md="3" align-self="center">
                      <v-text-field
                        dense
                        type="date"
                        hide-details="auto"
                        v-model="varTransportadorLocal.vencimentoPaut"
                        label="Vencimento Paut"
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="auto" md="3" align-self="center">
                      <v-text-field
                        dense
                        hide-details="auto"
                        v-model="varTransportadorLocal.permisso"
                        label="Permisso"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="auto" md="3" align-self="center">
                      <v-text-field
                        dense
                        type="date"
                        hide-details="auto"
                        v-model="varTransportadorLocal.vencimentoPermisso"
                        label="Vencimento Permisso"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="auto" md="3" align-self="center">
                      <v-text-field
                        dense
                        hide-details="auto"
                        v-model="varTransportadorLocal.permissoComplementar"
                        label="Permisso Complementar"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="auto" md="3" align-self="center">
                      <v-text-field
                        dense
                        type="date"
                        hide-details="auto"
                        v-model="
                          varTransportadorLocal.vencimentoPermissoComplementar
                        "
                        label="Vencimento Permisso Complementar"
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="auto" md="2" align-self="center">
                      <v-text-field
                        dense
                        hide-details="auto"
                        v-model="varTransportadorLocal.cep"
                        label="CEP"
                        v-mask="'#####-###'"
                        outlined
                        @blur="metObterDadosCep(varTransportadorLocal.cep)"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="auto" md="8" align-self="center">
                      <v-text-field
                        dense
                        hide-details="auto"
                        v-model="varTransportadorLocal.endereco"
                        label="Endereço"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="auto" md="2" align-self="center">
                      <v-text-field
                        dense
                        hide-details="auto"
                        v-model="varTransportadorLocal.numero"
                        label="Número"
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="auto" md="6" align-self="center">
                      <v-text-field
                        dense
                        hide-details="auto"
                        v-model="varTransportadorLocal.complemento"
                        label="Complemento"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="auto" md="6" align-self="center">
                      <v-text-field
                        dense
                        hide-details="auto"
                        v-model="varTransportadorLocal.bairro"
                        label="Bairro"
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="auto" md="5" align-self="center">
                      <v-autocomplete
                        dense
                        hide-details="auto"
                        v-model="varTransportadorLocal.idPais"
                        label="Pais"
                        :items="compPaisesSelect"
                        item-text="nome_pt"
                        item-value="id"
                        outlined
                        clearable
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="auto" md="5" align-self="center">
                      <v-text-field
                        dense
                        hide-details="auto"
                        v-model="varTransportadorLocal.cidade"
                        label="Cidade"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="auto" md="2" align-self="center">
                      <v-text-field
                        dense
                        hide-details="auto"
                        v-model="varTransportadorLocal.uf"
                        label="UF"
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="auto" md="6" align-self="center">
                      <v-text-field
                        dense
                        hide-details="auto"
                        v-model="varTransportadorLocal.email"
                        label="E-mail"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="auto" md="6" align-self="center">
                      <v-text-field
                        dense
                        hide-details="auto"
                        v-model="varTransportadorLocal.telefone"
                        label="Telefone"
                        v-mask="metMaskFone(varTransportadorLocal.telefone)"
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="auto" md="12" align-self="center">
                      <v-textarea
                        dense
                        hide-details="auto"
                        v-model="varTransportadorLocal.textoDadosLivres"
                        label="Dados Livres"
                        rows="2"
                        outlined
                      ></v-textarea>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="auto" md="12" align-self="center">
                      <v-textarea
                        dense
                        hide-details="auto"
                        v-model="varTransportadorLocal.observacoes"
                        label="Observações"
                        rows="2"
                        outlined
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </v-tab-item>
            <v-tab-item>
              <template>
                <v-container fluid>
                  <v-row dense>
                    <v-col cols="auto" md="6" align-self="center">
                      <v-text-field
                        dense
                        hide-details="auto"
                        v-model="varTransportadorLocal.emailFinanceiro"
                        label="E-mail Financeiro"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="auto" md="6" align-self="center">
                      <v-checkbox
                        dense
                        v-model="
                          varTransportadorLocal.sugerirNumeracaoSequencial
                        "
                        label="Sugerir Numeração Sequencial"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="auto" md="5" align-self="center">
                      <v-text-field
                        dense
                        hide-details="auto"
                        v-model="
                          varTransportadorLocal.codigoNumeroAbastecimento
                        "
                        label="Código Número Abastecimento"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="auto" md="5" align-self="center">
                      <v-text-field
                        dense
                        hide-details="auto"
                        v-model="varTransportadorLocal.rntrc"
                        label="RNTRC"
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="auto" md="4" align-self="center">
                      <v-text-field
                        dense
                        hide-details="auto"
                        v-model="varTransportadorLocal.tipoArquivo"
                        label="Tipo Arquivo"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="auto" md="3" align-self="center">
                      <v-checkbox
                        dense
                        v-model="varTransportadorLocal.utilizarDados"
                        label="Utilizar Dados"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="auto" md="5" align-self="center">
                      <v-select
                        dense
                        v-model="varTransportadorLocal.formaCobranca"
                        label="Forma Cobrança"
                        :items="varFormaCob"
                        outlined
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="auto" md="2" align-self="center">
                      <v-checkbox
                        dense
                        v-model="varTransportadorLocal.usoIssqn"
                        label="Usar ISSQN"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="auto" md="2" align-self="center">
                      <v-checkbox
                        dense
                        v-model="varTransportadorLocal.usoIrrf"
                        label="Usar IRRF"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="auto" md="2" align-self="center">
                      <v-checkbox
                        dense
                        v-model="varTransportadorLocal.usoPis"
                        label="Usar PIS"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="auto" md="2" align-self="center">
                      <v-checkbox
                        dense
                        v-model="varTransportadorLocal.usoCsll"
                        label="Usar CSLL"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="auto" md="3 " align-self="center">
                      <v-checkbox
                        dense
                        v-model="varTransportadorLocal.usoCofins"
                        label="Usar COFINS"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </v-tab-item>
          </v-tabs>

          <template>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="ml-4 mr-4 mb-4"
                  color="green darken-1"
                  v-bind="attrs"
                  v-on="on"
                  @click="submit"
                >
                  mdi-check-circle
                </v-icon>
              </template>
              <span>Salvar</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="mr-4 mb-4"
                  color="red darken-1"
                  v-bind="attrs"
                  v-on="on"
                  @click="metVoltar"
                >
                  mdi-backspace
                </v-icon>
              </template>
              <span>Cancelar</span>
            </v-tooltip>
          </template>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { validationMixin } from "vuelidate";
import DatasMixin from "@/mixins/DatasMixin";
import axios from "../_services/axios";

const { mapState, mapActions } = createNamespacedHelpers("cadTransportadores");

export default {
  mixins: [validationMixin, DatasMixin],
  // components: { tableCompras },
  // validations: {
  //   varTransportadorLocal: {
  //     nome_transportador: { required },
  //     email: { required, email },
  //     transportador: { required },
  //     senha: { required },
  //     ativo: { required },
  //   },
  // },

  data: () => ({
    name: "CadTransportadoresForm",
    tituloForm: "",
    varTransportadorLocal: {},
    varFormaCob: ["Boleto", "Conta", "Espécie"],
  }),

  computed: {
    ...mapState(["staTransportadorSelect", "staPaisesSelect"]),

    compPaisesSelect() {
      return this.staPaisesSelect;
    },
  },

  watch: {
    staTransportadorSelect(transportadorNovo) {
      this.metSincronizar(transportadorNovo);
    },
  },

  created() {
    this.metSincronizar(this.staTransportadorSelect);
    if (this.staTransportadorSelect) {
      this.tituloForm = "Editar Transportador";
    } else {
      this.tituloForm = "Nova Transportador";
    }
    this.actPaisesSelect();
    this.metDatasDB();
  },
  methods: {
    ...mapActions([
      "actSelecionarTransportador",
      "actResetarTransportador",
      "actCriarTransportador",
      "actEditarTransportador",
      "actResetarErro",
      "actPaisesSelect",
    ]),

    async metObterDadosCep(cep) {
      if (cep) {
        try {
          var dadosCep = await axios.getObterDadosCep(cep);
          this.varTransportadorLocal.endereco =
            dadosCep.data.tipo_logradouro + " " + dadosCep.data.nome_logradouro;
          this.varTransportadorLocal.bairro = dadosCep.data.bairro;
          this.varTransportadorLocal.cidade = dadosCep.data.nome_localidade;
          this.varTransportadorLocal.uf = dadosCep.data.uf;
        } catch (err) {
          console.log(err);
        }
      }
    },

    metMaskFone(fone) {
      if (fone == null) {
        return "";
      }
      if (fone.includes("(")) {
        return fone.length <= 13 ? "(##)####-####" : "(##)# ####-####";
      } else {
        return fone.length <= 10 ? "(##)####-####" : "(##)# ####-####";
      }
    },

    selecionarTransportadorEdicao(transportador) {
      this.actSelecionarTransportador({ transportador });
    },

    async salvarTransportador() {
      switch (this.staTransportadorSelect) {
        case undefined:
          this.varTransportadorLocal.criacao =
            this.$store.state.login.usuario.usuario;
          this.varTransportadorLocal.modificacao =
            this.$store.state.login.usuario.usuario;
          await this.actCriarTransportador({
            transportador: this.varTransportadorLocal,
            licenca: this.$store.state.login.licenca,
          });

          if (this.$store.state.cadTransportadores.staErro != undefined) {
            this.$root.snackbar.show({
              type: "danger",
              message: this.$store.state.cadTransportadores.staErro,
            });
            this.actResetarErro();
          } else {
            this.tituloForm = "Editar Transportador";
            this.$root.snackbar.show({
              type: "success",
              message: "Transportador Criado!",
            });
          }
          break;
        default:
          this.varTransportadorLocal.modificacao =
            this.$store.state.login.usuario.usuario;
          this.varTransportadorLocal.criado = undefined;
          this.varTransportadorLocal.criacao = undefined;
          this.varTransportadorLocal.modificado = undefined;
          await this.actEditarTransportador({
            transportador: this.varTransportadorLocal,
            licenca: this.$store.state.login.licenca,
          });

          if (this.$store.state.cadTransportadores.staErro != undefined) {
            this.$root.snackbar.show({
              type: "danger",
              message: this.$store.state.cadTransportadores.staErro,
            });
            this.actResetarErro();
          } else {
            this.$root.snackbar.show({
              type: "success",
              message: "Transportador Salvo!",
            });
          }
      }
      //this.resetar();
    },

    metDatasDB() {
      this.varTransportadorLocal.vencimentoApolice = this.mixDataDBDCal(
        this.varTransportadorLocal.vencimentoApolice
      );
      this.varTransportadorLocal.vencimentoPaut = this.mixDataDBDCal(
        this.varTransportadorLocal.vencimentoPaut
      );
      this.varTransportadorLocal.vencimentoPermisso = this.mixDataDBDCal(
        this.varTransportadorLocal.vencimentoPermisso
      );
      this.varTransportadorLocal.vencimentoPermissoComplementar =
        this.mixDataDBDCal(
          this.varTransportadorLocal.vencimentoPermissoComplementar
        );
    },

    metMaskCpfCnpj(n) {
      if (n == null) {
        return "";
      }
      return n.length <= 14 ? "###.###.###-##" : "##.###.###/####-##";
    },

    metSincronizar(transportador) {
      this.varTransportadorLocal = Object.assign(
        {},
        transportador || {
          id: null,
          endereco: null,
          bairro: null,
          cidade: null,
          uf: null,
        }
      );
    },
    submit() {
      // this.$v.$touch();
      this.salvarTransportador();
      // this.$router.back();
    },
    metClear() {
      //this.$v.$reset();
      // this.varTransportadorLocal.ultima_compra = this.staTransportadorSelect.ultima_compra;
      // this.varTransportadorLocal.email = this.staTransportadorSelect.email;
      // this.varTransportadorLocal.transportador = this.staTransportadorSelect.transportador;
      // this.varTransportadorLocal.senha = this.staTransportadorSelect.senha;
      // this.varTransportadorLocal.ativo = this.staTransportadorSelect.ativo;
    },
    metVoltar() {
      this.$router.back();
    },
  },
};
</script>
